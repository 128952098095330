<template>
  <vh-popover
    placement="right"
    title="UI规范demo导航"
    width="400"
    trigger="hover"
    :popper-options="{ zIndex: 5000 }"
  >
    <ul>
      <li>
        <a href="https://test01.e.vhall.com/v3/ui-standard/page-create?data=1" target="_blank">
          创建页1 (常规)
        </a>
      </li>
      <li>
        <a href="https://test01.e.vhall.com/v3/ui-standard/page-create?data=2" target="_blank">
          创建页2 (右侧待wap/pc预览效果)
        </a>
      </li>
      <li>
        <a href="https://test01.e.vhall.com/v3/ui-standard/page-list?data=0" target="_blank">
          无tab列表页+空数据
        </a>
      </li>
      <li>
        <a href="https://test01.e.vhall.com/v3/ui-standard/page-list?data=1" target="_blank">
          无tab列表页+有数据
        </a>
      </li>
      <li>
        <a href="https://test01.e.vhall.com/v3/ui-standard/page-list2" target="_blank">
          有tab列表页(大集合)
        </a>
      </li>
      <li>
        <a href="https://test01.e.vhall.com/v3/ui-standard/page-dialog" target="_blank">
          弹窗大集合
        </a>
      </li>
    </ul>
    <i class="iconfont-v3 saasicon_help_m" slot="reference" style="margin-left: 10px"></i>
  </vh-popover>
</template>
<script>
  export default {
    name: 'HelpPlopver'
  };
</script>
<style lang="less" scoped>
  ul,
  li {
    list-style: disc;
    line-height: 1.8;
    margin-left: 10px;
  }
</style>
