<template>
  <PageContainer>
    <template slot="title">
      <!-- NOTE: 标题栏: 开发人员根据业务调整 -->
      <pageTitle pageTitle="UI规范创建页标题">
        <div slot="content">
          1.输入框、按钮高度40px，使用标准vh-form，默认大小的按钮和文本框即可。
          <br />
          2.按钮宽度根据文字内容调整；
          <br />
          3.提交按钮距离上面内容统一padding：32px;
          <br />
          4. 表单项之间padding：32px。
          <br />
          5. 表单标题预留最长字段，距离左侧页边距48px;
        </div>
        <HelpPopover />
      </pageTitle>
    </template>

    <!-- 内容区 -->
    <BothContent3 v-if="data == 2" slot="content" />
    <div class="page-create-box" v-else slot="content">
      <vh-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="std-form">
        <vh-form-item label="观看语种" prop="languageVa">
          <div class="content-title">
            分组直播暂不支持多语言！仅生效网页观看端，不生效JS-SDK和移动SDK观看端
            <a href="https://saas-doc.vhall.com/opendocs/show/1384" target="_blank">功能介绍</a>
          </div>
          <div class="content-body" style="line-height: normal">
            <vh-checkbox-group :min="1" v-model="ruleForm.languageVa">
              <vh-checkbox label="lang_1">简体中文</vh-checkbox>
              <vh-checkbox label="lang_2">英文</vh-checkbox>
              <vh-checkbox label="lang_3">西班牙语</vh-checkbox>
            </vh-checkbox-group>
          </div>
        </vh-form-item>

        <vh-form-item label="直播标题" prop="name">
          <vh-input
            v-model="ruleForm.name"
            placeholder="请输入直播标题"
            maxlength="100"
            show-word-limit
            style="width: 560px"
          ></vh-input>
        </vh-form-item>

        <vh-form-item label="直播时间" required>
          <vh-col :span="11">
            <vh-form-item prop="date1">
              <vh-date-picker
                type="date"
                placeholder="选择日期"
                v-model="ruleForm.date1"
                style="width: 328px"
              ></vh-date-picker>
            </vh-form-item>
          </vh-col>
          <vh-col class="line" :span="1">-</vh-col>
          <vh-col :span="11">
            <vh-form-item prop="date2">
              <vh-time-picker
                placeholder="选择时间"
                v-model="ruleForm.date2"
                style="width: 180px"
              ></vh-time-picker>
            </vh-form-item>
          </vh-col>
        </vh-form-item>

        <vh-form-item label="直播模式">
          <div class="content-title">
            直播创建成功后，直播模式将不可修改，分组直播不支持免费观看，观看限制默认为“密码”
            <vh-tooltip v-tooltipMove>
              <div slot="content">
                <p>1.视频直播：音频+视频直播，需要保证摄像头和麦克风正常</p>
                <p>2.互动直播：音视频互动连麦，最多支持16人连麦直播</p>
                <p>3.音频直播：音频直播，需要保证麦克风正常</p>
                <p>
                  4.分组直播：一种特殊的实时互动直播，最高可容纳2000人，支持分组讨论功能，每组最大支持16人连麦讨论
                </p>
              </div>
              <i class="iconfont-v3 saasicon_help_m tip" style="color: #999999"></i>
            </vh-tooltip>
          </div>
          <div class="content-body content-body--live-mode">
            <!-- card 视频直播 -->
            <div class="content-body__box">
              <div
                class="content-body__card"
                :class="[{ 'is-active': ruleForm.liveMode == 2 }]"
                @click="liveModeChange(2)"
              >
                <img src="../../common/images/live/mode-video@2x.png" alt="" />
              </div>
              <p class="content-body__desc">视频直播</p>
            </div>
            <!-- card 互动直播 -->
            <div class="content-body__box">
              <div class="content-body__card is-disabled">
                <img src="../../common/images/live/mode-active@2x.png" alt="" />
              </div>
              <p class="content-body__desc">互动直播</p>
            </div>
            <!-- card 音频直播 -->
            <div class="content-body__box">
              <div
                class="content-body__card"
                :class="[{ 'is-active': ruleForm.liveMode == 1 }]"
                @click="liveModeChange(1)"
              >
                <img src="../../common/images/live/mode-media@2x.png" alt="" />
              </div>
              <p class="content-body__desc">音频直播</p>
            </div>
            <!-- 分组直播 -->
            <div class="content-body__box">
              <div
                class="content-body__card"
                :class="[{ 'is-active': ruleForm.liveMode == 6 }]"
                @click="liveModeChange(6)"
              >
                <img src="../../common/images/live/mode-group@2x.png" alt="" />
              </div>
              <p class="content-body__desc">分组直播</p>
            </div>
          </div>
        </vh-form-item>

        <vh-form-item label="直播延迟" required>
          <div class="content-title">
            直播创建成功后，直播延迟类型将不可修改，点我了解
            <vh-button type="text">无延迟直播</vh-button>
          </div>
          <div class="content-body">
            <div
              class="content-body__card"
              :class="[{ 'is-active': !ruleForm.noDelay }]"
              @click="handleNodelaySelect"
            >
              <i class="iconfont-v3 saasicon-changgui ft20"></i>
              常规延迟≈5S
            </div>
            <div
              class="content-body__card"
              :class="[{ 'is-active': ruleForm.noDelay }]"
              @click="handleNodelaySelect"
            >
              <i class="iconfont-v3 saasicon-wuyanchi ft20"></i>
              无延迟&lt;1S
            </div>
          </div>
        </vh-form-item>

        <vh-form-item label="直播标签" prop="tags">
          <vh-input
            placeholder="点击设置进行标签引用"
            v-model="ruleForm.tags"
            :readonly="true"
            style="width: 560px"
          >
            <vh-button slot="append" class="search-btn">设置</vh-button>
          </vh-input>
        </vh-form-item>

        <!-- TODO: 需要封装一个‘上传并剪裁’的业务组件 -->
        <vh-form-item label="PC封面">
          <vh-upload class="content-upload" action="https://jsonplaceholder.typicode.com/posts/">
            <i class="iconfont-v3 saasicon_shangchuan"></i>
            <div class="text-tips">
              <p>建议尺寸：1280*720px，小于4M</p>
              <p>支持jpg、gif、png、bmp</p>
            </div>
          </vh-upload>
        </vh-form-item>

        <!-- TODO: 需要封装一个‘上传并剪裁’的业务组件 -->
        <vh-form-item label="手机封面">
          <vh-upload class="content-upload" action="https://jsonplaceholder.typicode.com/posts/">
            <i class="iconfont-v3 saasicon_shangchuan"></i>
            <div class="text-tips">
              <p>建议尺寸：750*1334px，小于4M</p>
              <p>支持jpg、gif、png、bmp</p>
            </div>
          </vh-upload>
        </vh-form-item>

        <vh-form-item label="直播简介">
          <v-editor
            save-type="live"
            :placeholder="`请输入直播简介`"
            :isReturn="true"
            ref="unitImgTxtEditor"
            :maxWord="5000"
            v-model="ruleForm.description"
            style="width: 560px"
          ></v-editor>
        </vh-form-item>

        <vh-form-item label="实时字幕" prop="subtitle" class="auto-height">
          <div slot="label">
            <span class="label-title">实时字幕</span>
            <vh-switch
              v-model="ruleForm.subtitle"
              active-color="#FB3A32"
              inactive-color="#CECECE"
            ></vh-switch>
          </div>
          <div class="content-title switch-label">
            {{
              ruleForm.subtitle
                ? '已开启，直播时观众将能看到字幕。直播中不支持开启，请在直播前开启！'
                : '开启后，直播时观众将能看到字幕。直播中不支持开启，请在直播前开启！'
            }}
            <vh-button type="text">了解功能</vh-button>
          </div>
        </vh-form-item>

        <vh-form-item prop="docPageTurning" class="auto-height">
          <div slot="label">
            <span class="label-title">文档翻页</span>
            <vh-switch
              v-model="ruleForm.docPageTurning"
              active-color="#FB3A32"
              inactive-color="#CECECE"
            ></vh-switch>
          </div>
          <div class="content-title switch-label">
            {{
              ruleForm.docPageTurning
                ? '开启后，直播中观众可以提前预览文档，进行文档翻页'
                : '已开启，直播中观众可以提前预览文档，进行文档翻页'
            }}
          </div>
        </vh-form-item>

        <vh-form-item prop="reservation" class="auto-height">
          <div slot="label">
            <span class="label-title">预约人数</span>
            <vh-switch
              v-model="ruleForm.reservation"
              active-color="#FB3A32"
              inactive-color="#CECECE"
            ></vh-switch>
          </div>
          <div class="content-title switch-label">
            {{ ruleForm.reservation ? '已开启，观看端显示预约人数' : '开启后，观看端显示预约人数' }}
          </div>
        </vh-form-item>

        <vh-form-item prop="capacity" class="auto-height is-disabled">
          <div slot="label">
            <span class="label-title">并发扩充</span>
            <vh-switch
              v-model="ruleForm.capacity"
              disabled
              active-color="#FB3A32"
              inactive-color="#CECECE"
            ></vh-switch>
          </div>
          <div class="content-title switch-label">
            {{
              ruleForm.capacity
                ? '开启后，可以使用扩展包扩容并发人数（扩展包剩余0人）'
                : '已开启，可以使用扩展包扩容并发人数（扩展包剩余0人）'
            }}
          </div>
        </vh-form-item>

        <vh-form-item prop="limitCapacitySwtich" class="auto-height">
          <div slot="label">
            <span class="label-title">最高并发</span>
            <vh-switch
              v-model="ruleForm.limitCapacitySwtich"
              active-color="#FB3A32"
              inactive-color="#CECECE"
            ></vh-switch>
          </div>
          <div class="content-title switch-label">
            {{
              ruleForm.limitCapacitySwtich
                ? '已开启，限制进入活动的观众最大并发数'
                : '开启后，限制进入活动的观众最大并发数'
            }}
          </div>
          <div class="content-body" style="margin-top: 15px" v-if="ruleForm.limitCapacitySwtich">
            <vh-input
              v-model="ruleForm.limitCapacity"
              placeholder="请输入1-99999的并发数"
              style="width: 360px"
            ></vh-input>
          </div>
        </vh-form-item>

        <vh-form-item>
          <vh-button round type="primary" @click="submitForm('ruleForm')">保存</vh-button>
          <vh-button round type="info" plain @click="resetForm('ruleForm')">取消</vh-button>
        </vh-form-item>
      </vh-form>
    </div>
  </PageContainer>
</template>

<script>
  import HelpPopover from './HelpPopover.vue';
  import PageContainer from '@/components/PageContainer';
  import PageTitle from '@/components/PageTitle';
  import VEditor from '@/components/Tinymce';
  import BothContent3 from './BothContent3.vue';

  export default {
    components: {
      HelpPopover,
      PageContainer,
      PageTitle,
      VEditor,
      BothContent3
    },
    data() {
      return {
        data: 1,
        ruleForm: {
          languageVa: ['lang_1'], //观看语种
          name: '', // 直播标题
          date1: '', // 直播时间年月日
          date2: '', // 直播时间时分
          liveMode: 2, // 直播模式
          tags: '', // 直播标签
          description: '', // 直播简介
          subtitle: false, // 实时字幕
          docPageTurning: false, //文档翻页
          reservation: true, //预约人数
          noDelay: false, // 直播延迟
          capacity: false, // 并发扩充
          limitCapacitySwtich: true, //最高并发
          limitCapacity: undefined //最高并发具体数值
        },
        rules: {
          name: [
            { required: true, message: '请输入直播标题', trigger: 'blur' },
            { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
          ],
          date1: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
          date2: [{ type: 'date', required: true, message: '请选择时间', trigger: 'change' }]
        }
      };
    },
    methods: {
      liveModeChange(index) {
        this.ruleForm.liveMode = index;
      },
      handleNodelaySelect() {
        this.ruleForm.noDelay = !this.ruleForm.noDelay;
      },
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
    created() {
      this.data = this.$route.query.data || 1;
    }
  };
</script>
<style lang="less" scoped>
  .content-body--live-mode {
    .content-body__box {
      text-align: center;
      margin-left: 6px;

      &:first-child {
        margin-left: 0;
      }
    }

    .content-body__card {
      width: 185px;
      height: 116px;

      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        cursor: pointer;
      }
    }

    .content-body__desc {
      font-size: 14px;
      text-align: center;
      line-height: normal;
      color: #666;
      margin-top: 8px;
    }
  }
</style>
